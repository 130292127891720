<template>
    <fragment>
        <video style="width: 400px;height: 200px;object-fit: contain;" :src="value" controls="controls">
            您的浏览器不支持 video 标签。
        </video>
    </fragment>
</template>

<script>
    export default {
        name:'LuckyTableVideo',

        props: ['value'],
    }
</script>

<style scoped>

</style>