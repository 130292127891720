<template>
    <fragment>
        {{structure.config.values[0].label}}<a-switch class="switch-class" v-model:checked="checked" @change="change" />{{structure.config.values[1].label}}
    </fragment>
</template>

<script>
    export default {
        name:'LuckyTableSwitch',

        props: ['id','value','structure'],
        data(){
            return {
                switchData:'',
                checked:false
            }
        },
        mounted() {
            let that = this
            that.switchData = that.value
            if(that.switchData == that.structure.config.values[1].value){
                that.checked = true;
            }
        },

        methods: {
            change(){
                let that = this
                if(that.checked === true){
                    that.switchData = that.structure.config.values[1].value;
                }else {
                    that.switchData = that.structure.config.values[0].value;
                }

                let obj = {
                    'change_field':that.structure.field,
                    'change_value':this.switchData,
                    'id':this.id,
                }
                let handleInfo = {
                    handleType:'request',
                    requestUrl:that.structure.config.requestUrl
                }
                that.$parent.$parent.$parent.tableHandles(handleInfo,obj)
            },
        }
    }
</script>

<style scoped>
    .switch-class{
        margin: 0 10px;
    }
</style>