<template>
  <div class="content">
    <div class="up" @click="topMove"></div>
    <div class="down" @click="bottomMove"></div>
  </div>
</template>

<script>
export default {
  name: "LuckyTableOrder",
  props: ['id','value','structure'],
  data(){
    return {
      orderData:''
    }
  },
  mounted() {
    this.orderData = this.value
  },
  methods: {
    topMove() {
      this.orderData = this.orderData - 1*1
      this.change()
    },
    bottomMove() {
      this.orderData = this.orderData + 1*1
      this.change()
    },
    change(){
      let that = this
      let obj = {
        'change_field':that.structure.field,
        'change_value':this.orderData,
        'id':this.id,
      }
      let handleInfo = {
        handleType:'request',
        requestUrl:that.structure.config.requestUrl
      }
      that.$parent.$parent.$parent.tableHandles(handleInfo,obj)
    },
    
  },
};
</script>

<style scoped>
.content {
  width: 20px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
}
.up {
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid rgb(76, 120, 202);
  cursor: pointer;
}
.down {
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid rgb(76, 120, 202);
  cursor: pointer;
}
</style>