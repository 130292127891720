<template>
    <a-input
            size="large"
            v-model:value="inputData"
            @blur="change"
            allow-clear
            :style="{width:'100%'}"
    />
</template>

<script>
    export default {
        name:'LuckyTableInput',

        props: ['id','value','structure'],
        data(){
            return {
                inputData:''
            }
        },
        mounted() {
            this.inputData = this.value
        },

        methods: {
            change(){
                let that = this
                let obj = {
                    'change_field':that.structure.field,
                    'change_value':this.inputData,
                    'id':this.id,
                }
                let handleInfo = {
                    handleType:'request',
                    requestUrl:that.structure.config.requestUrl
                }
                that.$parent.$parent.$parent.tableHandles(handleInfo,obj)
            },
        }
    }
</script>

<style scoped>

</style>