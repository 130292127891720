<template>
    <fragment>
        <el-time-picker
                v-model="structure.value"
                placeholder="请选择时间"
                value-format="HH:mm:ss"
                clearable
                :style="{width:'100%'}"
                >
        </el-time-picker>
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>
<script>

    export default {
        name: 'LuckyTime',

        props: ['structure','showWarning'],

    };
</script>
