<template>
    <fragment>
        <a-tree
                v-model:checkedKeys="structure.value"
                checkable
                :selected-keys="selectedKeys"
                :tree-data="structure.config.values"
                @check="emitData"
                :replaceFields="{children:'children', title:'label', key:'value' }"
        />
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>
<script>

    export default {
        name: 'LuckyTree',

        props: ['structure','showWarning'],
        data() {
            return {
                selectedKeys: [],
            };
        },
        methods: {
            emitData(selectedKeys){
                this.selectedKeys = selectedKeys;
            },

        },

    };
</script>
