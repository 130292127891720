import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element'

import './plugins/ant'

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)


//常量
import custom from './custom';
Vue.prototype.$const = custom

//自定义表单子组件(放在这里引入是因为radio子组件会出现组件未注册的情况)
import {
  LuckyMap,LuckyInput,LuckySearch,LuckyTextarea,LuckyNumber,LuckyRadio,
  LuckyCheckbox,LuckySelect,LuckyDate,LuckyTime,LuckyCascader,LuckyTree,
  LuckyImage,LuckyImages,LuckyFile,LuckySwitch,LuckyEditor,LuckyPassword,
  LuckyMore
} from "@/components/form-components"
Vue.component("LuckyMap",LuckyMap)
Vue.component("LuckyInput",LuckyInput)
Vue.component("LuckySearch",LuckySearch)
Vue.component("LuckyTextarea",LuckyTextarea)
Vue.component("LuckyNumber",LuckyNumber)
Vue.component("LuckyCheckbox",LuckyCheckbox)
Vue.component("LuckySelect",LuckySelect)
Vue.component("LuckyDate",LuckyDate)
Vue.component("LuckyTime",LuckyTime)
Vue.component("LuckyCascader",LuckyCascader)
Vue.component("LuckyTree",LuckyTree)
Vue.component("LuckyImage",LuckyImage)
Vue.component("LuckyImages",LuckyImages)
Vue.component("LuckyFile",LuckyFile)
Vue.component("LuckySwitch",LuckySwitch)
Vue.component("LuckyEditor",LuckyEditor)
Vue.component("LuckyPassword",LuckyPassword)
Vue.component("LuckyMore",LuckyMore)
Vue.component("LuckyRadio",LuckyRadio)

//ajax请求
import axios from './plugins/axios';
Vue.prototype.$ajax = axios

//上传图片或文件
import upload from './plugins/upload';
Vue.prototype.$upload = upload

//MD5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;


//提示信息
import { Message } from 'element-ui'
Vue.prototype.$message = Message
import { MessageBox } from 'element-ui'
Vue.prototype.$messageBox = MessageBox

//echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

//粒子效果
import VueParticles from 'vue-particles'
Vue.use(VueParticles)


import '../public/tinymce/skins/ui/oxide/content.min.css'
import '../public/tinymce/skins/ui/oxide/content.css'
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
