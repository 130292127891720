<template>
    <fragment>
        <el-date-picker
                value-format="timestamp"
                v-model="structure.value"
                clearable
                :style="{width:'100%'}"
                :type="structure.config.type"
                placeholder="请选择日期">
        </el-date-picker>
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>

<script>
    export default {
        name: 'LuckyDate',

        props: ['structure','showWarning'],
    }
</script>

<style scoped>

</style>