<template>
  <div class="tinymce-editor">
    <editor v-model="myValue" :id="field" :init="init"></editor>
  </div>
</template>
<script>

  import tinymce from 'tinymce/tinymce'
  import Editor from '@tinymce/tinymce-vue'
  import 'tinymce/themes/silver'

  // 编辑器插件plugins
  // 更多插件参考：https://www.tiny.cloud/docs/plugins/
  import 'tinymce/plugins/image'// 插入上传图片插件
  import 'tinymce/plugins/media'// 插入视频插件
  import 'tinymce/plugins/table'// 插入表格插件
  import 'tinymce/plugins/lists'// 列表插件
  import 'tinymce/plugins/link'// 列表插件
  import 'tinymce/plugins/wordcount'// 字数统计插件
  import 'tinymce/icons/default'
  import 'tinymce/plugins/paste'

  export default {
    components: {
      Editor
    },
    props: {
      field: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
      // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
      baseUrl: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      plugins: {
        type: [String, Array],
        default: 'lists image media table wordcount link paste'
      },
      toolbar: {
        type: [String, Array],
        default: 'undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media pageembed link table'
      }
    },
    data () {
      return {
        init: {
          language_url: `${this.baseUrl}/tinymce/langs/zh_CN.js`,
          language: 'zh_CN',
          selector:"#"+this.field,
          skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
          content_css: `${this.baseUrl}/tinymce/skins/content/default/content.css`,
          // skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide-dark`, // 暗色系
          // content_css: `${this.baseUrl}/tinymce/skins/content/dark/content.css`, // 暗色系
          height: 500,
          plugins: this.plugins,
          toolbar: false,
          branding: false,
          menubar: true,
          paste_data_images: false, // 允许粘贴图像
          images_upload_handler: (blobInfo, success, failure) => {
            let that = this;
            that.$upload.uploadFile(that,blobInfo.blob(),'editor',success)
          },
          file_picker_types:'media',
          video_template_callback: function(data){
            return '<span class="mce-preview-object mce-object-video" contenteditable="false" data-mce-object="video" data-mce-p-allowfullscreen="allowfullscreen" data-mce-p-frameborder="no" data-mce-p-scrolling="no" data-mce-p-src='+data.source+' data-mce-p-width='+data.width+' data-mce-p-height='+data.height+' data-mce-p-controls="controls" data-mce-html="%20"> <video width='+data.width+' height='+data.height+' controls="controls"> <source src='+data.source+' type='+data.sourcemime+'></source> </video> </span>';
          },
          file_picker_callback: (callback, value, meta) => {
            let input = document.createElement('input');//创建一个隐藏的input
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'video/*');
            let that = this;
            input.onchange = function(){
              let fileObj = this.files[0];//选取第一个文件
              that.$upload.uploadFile(that,fileObj,'editor',callback);
            };
            //触发点击
            input.click();
          }
        },
        myValue:this.value,

      }
    },
    mounted () {
      tinymce.init({})
    },

    methods: {
      // 清空内容
      clear () {
        this.myValue = ''
      }
    },
    watch: {
      value (newValue) {
        this.myValue = newValue
      },
      myValue (newValue) {
        this.$emit('input', newValue)
      }
    }
  }

</script>
<style lang="less">
  .tinymce-editor{
    position: relative;
    z-index: 999;
  }

</style>
