// 引入axios
import axios from 'axios'
import router from '../router';
import const_ from '@/custom'
import { Message,Loading } from 'element-ui'
// 创建axios实例
const httpService = axios.create({
    baseURL: const_.apiURL, // url前缀
    // timeout: 15000 // 请求超时时间
});

// request拦截器
httpService.interceptors.request.use(
    config => {
        // 根据条件加入token-安全携带
        if (true) { // 需自定义
            // 让每个请求携带token
            config.headers['Authorization'] = localStorage.getItem('Authorization');
            config.headers['Content-Type'] = 'application/json;charset=UTF-8';
        }
        return config;
    },
    error => {
        // 请求错误处理
        Promise.reject(error);
    }
)

// respone拦截器
httpService.interceptors.response.use(
    response => {
        // 统一处理状态
        const res = response.data;
        if (res.code != 1) { // 需自定义
            // 返回异常
            return Promise.reject({
                status: res.code,
                message: res.message
            });
        } else {
            return response.data.data;
        }
    },
    // 处理处理
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '错误请求';
                    break;
                case 401:
                    error.message = '未授权，请重新登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = '请求错误,未找到该资源';
                    break;
                case 405:
                    error.message = '请求方法未允许';
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器端出错';
                    break;
                case 501:
                    error.message = '网络未实现';
                    break;
                case 502:
                    error.message = '网络错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网络超时';
                    break;
                case 505:
                    error.message = 'http版本不支持该请求';
                    break;
                default:
                    error.message = `未知错误${error.response.status}`;
            }
        } else {
            error.message = "连接到服务器失败";
        }
        return Promise.reject(error);
    }
)

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {},action = '') {
    if(const_.loadingNumber === 0){
        const_.loadingTime = new Date().getTime();
        const_.loadingInstance = Loading.service({ fullscreen: true, lock:true });
        const_.loadingNumber = 1
    }else {
        const_.loadingNumber ++
    }

    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'get',
            params: params
        }).then(response => {
            handleLoading()
            resolve(response);
            if(action)
                action(response)
        }).catch(error => {
            handleLoading()
            if(action)
                action(false)
            else {
                reject(error);
            }
            handleError(error);
        });
    }).catch(() => {});
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {},action = '',loading=true) {
    if(loading){
        if(const_.loadingNumber === 0){
            const_.loadingTime = new Date().getTime();
            const_.loadingInstance = Loading.service({ fullscreen: true, lock:true });
            const_.loadingNumber = 1
        }else {
            const_.loadingNumber ++
        }
    }

    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params
        }).then(response => {
            if(loading)
            handleLoading()
            resolve(response);
            if(action)
                action(response)
        }).catch(error => {
            if(loading)
                handleLoading()
            if(action)
                action(false)
            else {
                reject(error);
            }
            handleError(error);
        });
    }).catch((e) => {});

}

export function post2(url, params = {}) {

    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
            handleError(error);
        });
    }).catch((e) => {});

}


//统一处理loading
function handleLoading() {
    const_.loadingNumber --
    if(const_.loadingNumber === 0){
        let nowTime = new Date().getTime();
        if(nowTime - const_.loadingTime > 1000)
            const_.loadingInstance.close()
        else
            setTimeout(function()  {
                const_.loadingInstance.close()
            }, 800 - (nowTime - const_.loadingTime));
    }
}
//错误统一处理
function handleError(error) {
    Message.error({message:error.message,showClose: true,duration:5000})
    if(error.status == -1){
        const_.loadingInstance.close()
        const_.loadingNumber = 0
        const_.loadingTime = 0
        localStorage.removeItem('Authorization');
        router.push('/')
    }
}

export default {
    get,post,post2
}