<template>
    <fragment>
        {{structure.config.values[0].label}}<a-switch class="switch-class" v-model:checked="checked" @change="emitData()" />{{structure.config.values[1].label}}
        <span class="warning-class" style="display: block" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>
<script>
    export default {
        name: 'LuckySwitch',

        props: ['structure','showWarning'],
        data(){
          return {
              checked:false,
          }
        },
        mounted() {
            if(this.structure.value == this.structure.config.values[1].value){
                this.checked = true;
            }
        },
        methods: {
            emitData(){
                if(this.checked === true){
                    this.structure.value = this.structure.config.values[1].value;
                }else {
                    this.structure.value = this.structure.config.values[0].value;

                }
            }
        }
    };
</script>
<style scoped>
    .switch-class{
        margin: 0 10px;
    }
</style>
