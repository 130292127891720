<template>
    <fragment>

        <el-button style="margin-left: 0;margin-right: 10px;" :type="isCollapse?'danger':'primary'" size="small" @click="collapse">{{collapseMsg}}</el-button>
        <span>
            <br>
        经度 <a-input style="width: 200px;margin-top: 10px" @change="change()" size="large" v-model:value="lng" allow-clear/>
            <br>
        纬度 <a-input style="width: 200px;margin-top: 10px" @change="change()" size="large" v-model:value="lat" allow-clear/>
        </span>
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>
        <div v-show="isCollapse" id="container" class="map"></div>
    </fragment>
</template>

<script>
    import _$ from "jquery";

    export default {
        name: 'LuckyMap',
        props: ['structure','showWarning'],
        data(){
            return {
                isCollapse:false,
                collapseMsg:"打开",
                map:{},
                maker:{},
                lng:'',
                lat:''

            }
        },
        mounted() {
            let that = this
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = 'https://webapi.amap.com/maps?v=1.4.15&key='+that.structure.config.key+'&plugin=AMap.Autocomplete';
            document.body.appendChild(s);
            s.onload = ()=>{
                that.map = new AMap.Map("container", {
                    resizeEnable: true
                });
                if(that.structure.value.length > 0){
                    let lng = that.structure.value.split(',')[0]
                    let lat = that.structure.value.split(',')[1]
                    that.addMarker(lng,lat)
                    that.lng = lng
                    that.lat = lat
                }
                that.map.on('click', function(e) {
                    that.structure.value = e.lnglat.getLng() + ',' + e.lnglat.getLat()
                    that.lng =  e.lnglat.getLng()
                    that.lat = e.lnglat.getLat()
                    that.addMarker(e.lnglat.getLng(),e.lnglat.getLat())
                });
            }
        },
        methods: {
            change(){
                let that = this
                that.structure.value = that.lng + ',' + that.lat

                that.addMarker(that.lng,that.lat)
            },
            addMarker(Lng,Lat) {
                let that = this
                that.clearMarker()

                let icon = new AMap.Icon({
                    size: new AMap.Size(22, 30),    // 图标尺寸
                    image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',  // Icon的图像
                    imageSize: new AMap.Size(22, 30)   // 根据所设置的大小拉伸或压缩图片
                });

                that.marker = new AMap.Marker({
                    icon: icon,
                    position: [Lng,Lat],
                    offset: new AMap.Pixel(-13, -30)
                });

                that.marker.setMap(that.map);
            },
            clearMarker() {
                let that = this
                if (that.marker) {
                    that.marker.setMap(null);
                    that.marker = null;
                }
            },
            //展开收起富文本编辑器
            collapse(){
                if(this.isCollapse){
                    let toolbar = document.getElementsByClassName("tox-tbtn--enabled");
                    if(toolbar){
                        _$('.tox-tbtn--enabled').click()
                    }
                    this.isCollapse = false;
                    this.collapseMsg = "打开"

                }else{
                    this.isCollapse = true;
                    this.collapseMsg = "收起"

                }
            },
        }
    }
</script>

<style>
    .map{
        z-index: 999;
        height: 300px;
        width: 600px;
        float: left;
        border-radius: 5px;
        border: 1px solid slategrey;
    }
    .amap-icon{
        width: 10px;
        height: 10px;
    }
</style>