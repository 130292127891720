<template>
    <fragment>
        <a-cascader
                size="large"
                :style="{width:'100%'}"
                v-model:value="structure.value"
                :options="structure.config.values"
                :getPopupContainer="(triggerNode)=>{ return triggerNode.parentNode}"
                placeholder="请选择..."
        />
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>
    </fragment>
</template>
<script>
    export default {
        name: 'LuckyCascader',
        props: ['structure','showWarning'],
    };
</script>
