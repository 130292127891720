<template>
    <fragment>
        <a-icon v-if="value" :type="value" />
    </fragment>
</template>

<script>
    export default {
        name:'LuckyTableIcon',

        props: ['value'],
    }
</script>

<style scoped>

</style>