<template>
    <fragment>
        <a-select
                size="large"
                v-model:value="selectData"
                ref="select"
                :style="{width:'100%'}"
                @change="change"
        >
            <a-select-option value="">
                请选择...
            </a-select-option>
            <a-select-option v-for="(option,optionIndex) in structure.config.values" :key="optionIndex" :value="option.value">
                {{option.label}}
            </a-select-option>
        </a-select>
    </fragment>
</template>

<script>
    export default {
        name:'LuckyTableSelect',

        props: ['id','value','structure'],
        data(){
            return {
                selectData:''
            }
        },
        mounted() {
            this.selectData = this.value
        },

        methods: {
            change(){
                let that = this
                let obj = {
                    'change_field':that.structure.field,
                    'change_value':this.selectData,
                    'id':this.id,
                }
                let handleInfo = {
                    handleType:'request',
                    requestUrl:that.structure.config.requestUrl
                }
                that.$parent.$parent.$parent.tableHandles(handleInfo,obj)
            },
        }
    }
</script>

<style scoped>

</style>