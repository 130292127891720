<template>
    <fragment>
        <a-textarea
                v-model:value="structure.value"
                allow-clear
                :rows="4"
        />
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>

<script>
    export default {
        name: 'LuckyTextarea',

        props: ['structure','showWarning'],

    }
</script>

<style scoped>

</style>