import { Message,Loading } from 'element-ui'

import const_ from '@/custom'
import OSS from 'ali-oss'
import COS from 'cos-js-sdk-v5'

async function uploadFile(that,fileObj,component='file',fun) {
    if(const_.loadingNumber === 0){
        const_.loadingTime = new Date().getTime();
        const_.loadingInstance = Loading.service({text: '文件正在上传，请耐心等待······', fullscreen: true, lock:true });
        const_.loadingNumber = 1
    }else {
        const_.loadingNumber ++
    }

    let formData = new FormData();
    formData.append("file", fileObj);
    formData.append("type", component);
    formData.append("size", Math.round(fileObj.size/1024/1024*100)/100);

    that.$ajax.post('/lucky/File/getUploadType', {}).then(res=>{
        if(res.upload_type == 'localhost'){
            //服务器上传
            that.$ajax.post('/lucky/File/uploadFile',formData).then(res=>{
                handleResult(component,fun,res)
            });
        }else {
            //前端上传
            const point = fileObj.name.lastIndexOf('.')
            let fileName = fileObj.name.substr(0, point) + fileObj.name.substr(point)
            let nowDate = new Date();
            let date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate()
            }
            let timestamp = new Date().getTime()
            let rand_num = Math.floor(Math.random() * (99999 - 10000) + 10000);
            let new_name = parseFloat(timestamp/1000).toFixed(0)*1 + rand_num*1
            new_name = 'files/'+ date.year + date.month + date.date + '/'+ that.$md5(new_name + '') + fileObj.name.substr(point)

            if(res.upload_type == 'oss'){
                //oss分片上传
                try {
                    let client = OSS({
                        accessKeyId: res.oss_access_key_id,
                        accessKeySecret: res.oss_access_key_secret,
                        bucket: res.oss_bucket,
                        region: res.oss_region
                    })

                    client.multipartUpload(new_name, fileObj, {
                        progress:  function (p) {
                            if(p == 1){
                                let url = "http://"+ res.oss_bucket + '.' + res.oss_region + ".aliyuncs.com/" + new_name
                                frontUploadSuccess(that,component,fun, {'type':component,'upload_type':'oss','name':fileName,'url':url,'size':Math.round(fileObj.size/1024/1024*100)/100})
                            }
                        }
                    });

                } catch (error) {
                    Message.error({message:"上传失败，请稍后重试",showClose: true,duration:5000})
                }
            }
            if(res.upload_type == 'cos'){
                //cos分片上传
                try {
                    // 初始化实例
                    var cos = new COS({
                        SecretId: res.cos_secret_id,
                        SecretKey: res.cos_secret_key,
                    });
                    cos.sliceUploadFile({
                        Bucket: res.cos_bucket,
                        Region:  res.cos_region,
                        Key: new_name,
                        Body: fileObj,
                    }, function (err, data) {
                        if(err)
                            console.log(err, data);
                        else{
                            let url = "http://"+res.cos_bucket+'.cos.'+ res.cos_region + '.myqcloud.com/'+ new_name;
                            frontUploadSuccess(that,component,fun, {'type':component,'upload_type':'cos','name':fileName,'url':url,'size':Math.round(fileObj.size/1024/1024*100)/100})
                        }
                    });

                } catch (error) {
                    Message.error({message:"上传失败，请稍后重试",showClose: true,duration:5000})

                }
            }
        }


    });
}
function frontUploadSuccess(that,component,fun,formData) {
    if(const_.loadingNumber === 0){
        const_.loadingTime = new Date().getTime();
        const_.loadingInstance = Loading.service({text: '文件正在上传，请耐心等待······', fullscreen: true, lock:true });
        const_.loadingNumber = 1
    }else {
        const_.loadingNumber ++
    }
    that.$ajax.post('/lucky/File/frontUpload',formData).then(res=>{
        handleResult(component,fun,res)
    });
}
function uploadImage(that,formData,component='image',fun) {
    if(const_.loadingNumber === 0){
        const_.loadingTime = new Date().getTime();
        const_.loadingInstance = Loading.service({text: '文件正在上传，请耐心等待······', fullscreen: true, lock:true });
        const_.loadingNumber = 1
    }else {
        const_.loadingNumber ++
    }

    that.$ajax.post('/lucky/File/uploadImage',formData,).then(res=>{
        handleResult(component,fun,res)
    });
}
function handleResult(component,fun,uploadResult) {
    handleLoading()
    if(component == 'editor'){
        //富文本编辑器
        fun(uploadResult.url)
    }else {
        //一般上传
        fun(uploadResult)
    }

}
//统一处理loading
function handleLoading() {
    const_.loadingNumber --
    if(const_.loadingNumber === 0){
        let nowTime = new Date().getTime();
        if(nowTime - const_.loadingTime > 1000)
            const_.loadingInstance.close()
        else
            setTimeout(function()  {
                const_.loadingInstance.close()
            }, 1200 - (nowTime - const_.loadingTime));
    }
}
function downloadFile(url) {
    let alink = document.createElement("a");
    alink.href = const_.apiURL+'/lucky/File/downloadFile?url='+url;
    alink.target = "_blank";
    alink.click();
}
export default {
    uploadFile,uploadImage,downloadFile
}