<template>
    <fragment>
        <a-input-number
                size="large"
                v-model:value="structure.value"
                :min="structure.config.min"
                :max="structure.config.max"
                :step="structure.config.step"
                :style="{width:'100%'}"
        />
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>

<script>
    export default {
        name: 'LuckyNumber',

        props: ['structure','showWarning'],

    }
</script>

<style scoped>

</style>