<template>
    <fragment>
        <div v-for="(item,index) in allComponents" :key="index" v-show="item.status">
            <component v-show="item.status" :breadCrumb="breadcrumb" :componentStatus="item.status" :is="'Lucky'+item.pageType" :componentData="item" :componentIndex="index"></component>
        </div>
    </fragment>
</template>

<script>
    export default {
        name:'LuckyLayout',
        props:['paneData'],
        data(){
            return {
                breadcrumb:[],         //面包屑
                allComponents:[],      //已显示的组件
            }
        },
        mounted() {
            let that = this;
            //初始化打开的组件
            that.allComponents.push({
                pageType:that.paneData.type,
                requestUrl:that.paneData.url,
                obj:{},
                status:true
            })
        },
        methods: {

            //插入组件
            openComponent(obj){
                let that = this;
                //修改所有组件状态为隐藏
                that.allComponents.forEach((item,index)=>{
                    that.allComponents[index]['status'] = false;
                });

                //插入新组件
                that.allComponents.push({
                    pageType:obj.page,
                    requestUrl:obj.requestUrl,
                    obj:obj.obj,
                    status:true
                });
                that.breadcrumb.push(obj.name)
            },
            //关闭组件
            closeComponent(componentIndex){
                let that = this;
                that.allComponents.forEach((item,index)=>{
                    if(componentIndex == index){
                        //关闭组件
                        that.allComponents.splice(index, 1);
                        //修改面包屑
                        that.breadcrumb.splice(index - 1, 1)
                    }
                    if(index == componentIndex-1){
                        //修改上一级组件状态为显示
                        that.allComponents[index]['status'] = true;
                    }
                });
            },

        },

    }
</script>

<style scoped>

</style>