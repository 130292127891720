<template>
    <a-input-number
            size="large"
            v-model:value="numberData"
            :min="structure.config.min"
            :max="structure.config.max"
            :step="structure.config.step"
            :style="{width:'100%'}"
            @blur="change"
    />
</template>

<script>
    export default {
        name:'LuckyTableNumber',

        props: ['id','value','structure'],
        data(){
            return {
                numberData:''
            }
        },
        mounted() {
            this.numberData = this.value
        },

        methods: {
            change(){
                let that = this
                let obj = {
                    'change_field':that.structure.field,
                    'change_value':this.numberData,
                    'id':this.id,
                }
                let handleInfo = {
                    handleType:'request',
                    requestUrl:that.structure.config.requestUrl
                }
                that.$parent.$parent.$parent.tableHandles(handleInfo,obj)
            },
        }
    }
</script>

<style scoped>

</style>