<template>
    <el-image
            v-if="value.length > 0"
            :style="'width:' + structure.config.width + 'px'"
            :src="value[0]"
            fit="contain"
            :preview-src-list="value">
    </el-image>
</template>

<script>
    export default {
        name:'LuckyTableImages',

        props: ['id','value','structure'],
    }
</script>

<style scoped>

</style>