<template>
    <fragment><span v-html="value"></span></fragment>
</template>

<script>
    export default {
        name:'LuckyTableVarchar',

        props: ['value'],
    }
</script>

<style scoped>

</style>