<template>
    <fragment>
        <a-row class="buttons-class">
            <el-button size="small" type="info" v-if="showCloseButton" @click="closeComponent()">返回</el-button>
            <el-button size="small" type="success" @click="submit" v-if="!onlyRead">提交</el-button>
            <el-button size="small" type="warning" @click="reset" v-if="!onlyRead">重置</el-button>
            <a-breadcrumb :style="{paddingLeft:'10px',height:'32px',lineHeight:'32px',float:'right',marginRight:'20px'}">
                <a-breadcrumb-item v-for="(item,index) in breadCrumb" :key="index">{{ item }}</a-breadcrumb-item>
            </a-breadcrumb>
        </a-row>
        <a-row class="form-content-class" >
            <a-col :span="6" :style="{ padding:'10px' }" v-for="(col,colIndex) in formStructure" :key="colIndex">
                <a-form layout="vertical">
                    <fragment v-for="(row,rowIndex) in col" :key="rowIndex">
                        <a-form-item :label="row.label">
                            <component
                                    :key="timer+ roundNumber +'colIndex'+ colIndex + 'rowIndex' + rowIndex"
                                    :is="'Lucky'+row.component"
                                    :showWarning="true"
                                    :structure="row"
                            />
                        </a-form-item>
                    </fragment>
                </a-form>
            </a-col>

        </a-row>
    </fragment>
</template>

<script>
    export default {
        name:'LuckyForm',
        props: ['componentData','componentIndex','componentStatus','breadCrumb'],
        data(){
            return {
                formStructure:{},    //表单结构
                submitUrl:'',        //提交链接
                onlyRead:false,         //仅读模式
                timer:'',
                roundNumber:'',
                showCloseButton:false   //是否显示返回按钮
            }
        },
        watch: {
            //bread面包屑控制
            componentStatus(newName, oldName) {
                let that = this;
                if(!oldName && newName){
                    that.getStructure()
                }
            },

        },
        mounted() {
            let that = this
            //是否展示返回按钮
            that.showCloseButton = that.componentIndex ? true : false ;
            that.getStructure()
        },
        methods:{
            //返回
            closeComponent(){
                let that = this;
                that.$messageBox.confirm("该操作会导致未保存的数据丢失，请谨慎操作！", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.$parent.closeComponent(that.componentIndex)
                }).catch(action => {
                });
            },
            //重置
            reset(){
                let that = this;
                that.$messageBox.confirm("该操作会导致未保存的数据丢失，请谨慎操作！", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.getStructure()
                }).catch(action => {
                });
            },
            //获取结构和数据
            getStructure(){
                let that = this;
                //判断是否是从其他页面跳转过来，并带来参数
                let obj;
                if(that.componentData.hasOwnProperty('obj')){
                    obj = that.componentData.obj;
                }
                that.$ajax.post(that.componentData.requestUrl, obj)
                    .then(res=>{
                        that.timer = new Date().getTime()
                        that.roundNumber = Math.floor(Math.random() * (9999 - 1 + 1)) + 1
                        that.formStructure = res.structure;
                        that.submitUrl = res.submitUrl;

                        if(!that.submitUrl)
                            that.onlyRead = true
                    })
            },
            //提交
            submit(){
                let that = this;
                that.$messageBox.confirm("确定要提交吗！", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.$ajax.post(that.submitUrl, that.formStructure,that.submitResult)
                        .then(res=>{
                            if(res.length > 0){
                                if(that.$parent.allComponents.length === 1){
                                    that.$message.success(res)
                                }else {
                                    setTimeout(function () {
                                        that.$messageBox.confirm(res + " 。需要返回上一页吗？", '提示', {
                                            confirmButtonText: '返回',
                                            cancelButtonText: '取消',
                                            type: 'warning'
                                        }).then(() => {
                                            that.$parent.closeComponent(that.componentIndex)
                                        }).catch(action => {
                                        });
                                    },1000)
                                }
                            }
                        })
                }).catch(action => {

                });
            },
            submitResult(res){
                let that = this
                if(res.length === 0)
                {
                    if(that.showCloseButton)
                        that.$parent.closeComponent(that.componentIndex)
                    else
                        that.getStructure()
                }
            }

        }
    }
</script>

<style scoped>
    .buttons-class{
        margin-left: 10px;
        margin-top: 10px;
        height: 40px;
        border-bottom:1px solid #d9d9d9;
    }
    .form-content-class{
        height:calc(100vh - 245px);
        overflow-y: auto;
        padding: 0 5px;
    }

    .ant-form-item{
        margin-bottom: 0;
        padding-bottom: 0;
    }

</style>