<template>
        <fragment>
            <a-select
                    size="large"
                    v-model:value="structure.value"
                    ref="select"
                    :getPopupContainer="(triggerNode)=>{ return triggerNode.parentNode}"
                    :style="{width:'100%'}"
            >
                <a-select-option value="">
                    请选择...
                </a-select-option>
                <a-select-option v-for="(option,optionIndex) in structure.config.values"  :value="option.value">
                    {{option.label}}
                </a-select-option>
            </a-select>

            <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

        </fragment>
</template>
<script>
    export default {
        name: 'LuckySelect',
        props: ['structure','showWarning'],
    };
</script>
