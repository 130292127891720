<template>
  <router-view></router-view>
</template>
<script>

  export default {
    name: 'app',

  }

</script>

<style lang="less">
  html,body{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  /*滚动条设置*/
  /* 滚动条宽度 */
  ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }
  /* 滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #a1a3a9;
    border-radius: 2px;
  }
  * {
    scrollbar-color: #a1a3a9 rgba(0, 0, 0, 0); /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
  }

</style>
