<template>
    <fragment>
        <el-upload
                class="upload-demo"
                action=""
                :limit="limit"
                :http-request="uploadFiles"
                :file-list="values"
                :before-remove="remove"
                list-type="picture">
            <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

        <el-dialog class="dialog-class" title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.outputSize"
                            :outputType="option.outputType"
                            :info="option.info"
                            :canScale="option.canScale"
                            :autoCrop="option.autoCrop"
                            :autoCropWidth="option.autoCropWidth"
                            :autoCropHeight="option.autoCropHeight"
                            :fixed="option.fixed"
                            :fixedBox="option.fixedBox"
                            :enlarge="option.enlarge"
                            :centerBox="option.centerBox"
                            :mode="option.mode"
                            :high="option.high"
                    ></vueCropper>

                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="cropperUpload" :loading="loading">确认</el-button>
            </div>
        </el-dialog>

    </fragment>
</template>

<script>
    import {VueCropper} from 'vue-cropper'
    export default {
        name: 'LuckyImages',

        props: ['structure','showWarning'],
        components: {
            VueCropper
        },
        data(){
            return{
                values:[],
                size:0,
                limit:1,
                style:{
                    width:'',
                    height:'',
                },
                zoom:1,
                dialogVisible: false,
                // 裁剪组件的基础配置option
                option: {
                    img: '', // 裁剪图片的地址
                    info: false, // 裁剪框的大小信息
                    outputSize: 1, // 裁剪生成图片的质量
                    outputType: 'jpeg', // 裁剪生成图片的格式
                    canScale: true, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    autoCropWidth: 0, // 默认生成截图框宽度
                    autoCropHeight: 0, // 默认生成截图框高度
                    fixedBox: true, // 固定截图框大小 不允许改变
                    fixed: false, // 是否开启截图框宽高固定比例
                    enlarge: 1,  //输出比例
                    centerBox: true, // 截图框是否被限制在图片里面
                    mode: 'cover', // 图片默认渲染方式
                    high: false, // 是否按照设备的dpr 输出等比例图片
                },
                // 防止重复提交
                loading: false,
                //图片对象
                fileObj:{}
            }
        },
        watch:{
            dialogVisible(val){
                if(!this.dialogVisible)
                    this.loading = false
            }
        },
        mounted() {
            this.values = JSON.parse(JSON.stringify(this.structure.value));
            this.size = this.structure.config.size;
            this.limit = this.structure.config.limit;

            if(this.structure.config.hasOwnProperty('width')){
                if(Number(this.structure.config.width) < Number(this.structure.config.height)){
                    if(Number(this.structure.config.height) > 400){
                        this.option.autoCropHeight = 400
                        this.option.autoCropWidth = 400*(this.structure.config.width/this.structure.config.config.height)
                        this.option.enlarge = this.structure.config.height/400
                    }else {
                        this.option.autoCropWidth = this.structure.config.width
                        this.option.autoCropHeight = this.structure.config.height
                    }
                }else {
                    if(Number(this.structure.config.width) > 400){
                        this.option.autoCropWidth = 400;
                        this.option.autoCropHeight = 400*(this.structure.config.height/this.structure.config.width);
                        this.option.enlarge = this.structure.config.width/400
                    }else {
                        this.option.autoCropWidth = this.structure.config.width
                        this.option.autoCropHeight = this.structure.config.height
                    }
                }
            }

        },
        methods: {
            uploadFiles(param){
                let that = this;
                that.fileObj = param.file;
                if(!this.structure.config.hasOwnProperty('width')){
                    if (that.fileObj.size / 1024 / 1024 > this.size) {
                        this.$message.error({message:"图片大小不能超过 "+this.size+"MB!",showClose: true});
                        return false;
                    }
                    let formData = new FormData();
                    formData.append("image", that.fileObj);
                    formData.append("name", that.fileObj.name);
                    formData.append("size", Math.round(that.fileObj.size/1024/1024*100)/100);
                    formData.append("width", that.structure.config.width);
                    formData.append("height", that.structure.config.height);
                    that.$upload.uploadImage(that,formData,'image',that.success)
                }else {
                    that.$nextTick(() => {
                        that.option.img = URL.createObjectURL(param.file);
                        that.dialogVisible = true
                    })
                }
            },
            cropperUpload() {
                let that =this
                that.$refs.cropper.getCropBlob((data) => {
                    this.loading = true
                    let newFile = new File([data], that.fileObj.name, {type: that.fileObj.type});
                    if (newFile.size / 1024 / 1024 > this.size) {
                        this.$message.error({message:"图片大小不能超过 "+this.size+"MB!",showClose: true});
                        return false;
                    }
                    let formData = new FormData();
                    formData.append("image", newFile);
                    formData.append("name", that.fileObj.name);
                    formData.append("size", Math.round(that.fileObj.size/1024/1024*100)/100);
                    formData.append("width", that.structure.config.width);
                    formData.append("height", that.structure.config.height);

                    that.$upload.uploadImage(that,formData,'image',that.success)
                })
            },
            success(response){
                this.structure.value.push(response)
                this.dialogVisible = false
                this.loading = false
            },
            remove(file, fileList){
                fileList.forEach((item,index)=>{
                   if(file.uid == item.uid){
                       this.structure.value.splice(index,1);
                   }
                });
            },

        }
    }
</script>

<style scoped>
    .avatar-uploader-icon {
        border: 1px dashed #d9d9d9;
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
        border-radius: 6px;

    }
    .avatar {
        border: 1px dashed #d9d9d9;
        width: 178px;
        height: 178px;
        display: block;
        border-radius: 6px;

    }
</style>


