<template>
        <fragment>
                <a-input-password
                        size="large"
                        v-model:value="structure.value"
                        allow-clear
                        :disabled="show"
                />
                <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>
        </fragment>
</template>

<script>
        export default {
                name: 'LuckyPassword',

                props: ['structure','showWarning'],
                data(){
                        return {
                                show:false,
                        }
                },
                mounted() {
                        if(this.structure.hasOwnProperty('config') &&
                                this.structure.config.hasOwnProperty('disabled'))
                                this.show = this.structure.config.disabled
                }
        }
</script>

<style scoped>

</style>