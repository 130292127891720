<template>
    <fragment>
        <a-select
                show-search
                placeholder="请输入 ...."
                size="large"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                @change="handleChange"
        >
            <a-select-option v-for="(option,optionIndex) in dataSource" :value="option">
                {{ option.label }}
            </a-select-option>
        </a-select>
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>

<script>
    export default {
        name: "LuckySearch",
        props: ['structure','showWarning'],
        data() {
            return {
                dataSource: [],
            };
        },

        methods:{
            handleSearch(value,aaa) {
                let that = this;
                that.$ajax.post(that.structure.config.optionUrl + value,{},'',false)
                    .then(res=>{
                        that.dataSource = res
                    })
            },
            handleChange(value){
                this.structure.value = value.value
            }
        }
    }
</script>

<style scoped>

</style>