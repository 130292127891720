<template>
    <fragment>
        <el-upload
                :file-list="value"
                action=""
                :limit="limit"
                :before-remove="remove"
                :before-upload="beforeUpload"
                :http-request="uploadFiles"
                :on-preview="downloadFile">
            <el-button size="small" type="primary" >点击上传</el-button>
        </el-upload>
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>

<script>
    export default {
        name: 'LuckyFile',
        props: ['structure','showWarning'],
        data(){
            return{
                value:[],
                size:0,
                limit:1,
            }
        },
        mounted() {
            this.value = JSON.parse(JSON.stringify(this.structure.value));
            this.size = this.structure.config.size;
            this.limit = this.structure.config.limit;
        },
        methods: {
            beforeUpload(file) {
                let extensions = this.structure.config.extension;
                if(extensions.length > 0){
                    let fileExtension = file.name.split('.').pop().toLowerCase();
                    let extensionStatus = false
                    if(extensions.toString().indexOf(fileExtension) > -1) {
                        extensionStatus = true
                    }

                    if(!extensionStatus){
                        this.$message.error({message:"文件必须为以下格式："+extensions.toString(),showClose: true});
                        return false;
                    }
                }

                if (file.size / 1024 / 1024 > this.size) {
                    this.$message.error({message:"文件大小不能超过 "+this.size+"MB!",showClose: true});
                    return false;
                }
                return true;
            },
            uploadFiles(param){
                let that = this;
                let fileObj = param.file;

                that.$upload.uploadFile(that,fileObj,'file',this.success)
            },
            success(response){
                this.structure.value.push(response)
            },
            remove(file, fileList){
                fileList.forEach((item,index)=>{
                    if(file.uid == item.uid){
                        this.structure.value.splice(index,1);
                    }
                });
            },
            downloadFile:function (file) {
                this.$messageBox.confirm('确认下载？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$upload.downloadFile(file.url)
                }).catch(action => {
                });
            }
        }
    }
</script>

<style scoped>

</style>