<template>
    <fragment>
        <a-radio-group size="large"  v-model:value="structure.value">
            <a-radio @click="showChildrenItem(radio,radioIndex)" v-for="(radio,radioIndex) in structure.config.values" :key="radioIndex" :value="radio.value">
                {{radio.label}}
            </a-radio>
        </a-radio-group>


        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>
    </fragment>
</template>

<script>
    export default {
        name: 'LuckyRadio',
        props: ['structure','showWarning'],
    }
</script>

<style scoped>

</style>