import Vue from 'vue'
import {
    Layout,Menu,Tabs,Form,Col,Row,Table,
    Input,InputNumber,Radio,Switch,Breadcrumb,
    Select,Checkbox,Cascader,Tree,Icon,Drawer,Modal
} from 'ant-design-vue'

Vue.use(Layout);Vue.use(Menu);Vue.use(Tabs);Vue.use(Drawer);
Vue.use(Form);Vue.use(Col);Vue.use(Row);Vue.use(Table);Vue.use(Breadcrumb);
Vue.use(Input);Vue.use(InputNumber);Vue.use(Radio);Vue.use(Switch);Vue.use(Modal);
Vue.use(Select);Vue.use(Checkbox);Vue.use(Cascader);Vue.use(Tree);Vue.use(Icon);
